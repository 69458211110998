import { chrome } from '@/guides/endpoints/chrome'
import { list as contact } from '@/guides/endpoints/contact'
import { list as news } from '@/guides/endpoints/news'
import { me, profile, avatars } from '@/guides/endpoints/profile'
import { list as todo } from '@/guides/endpoints/todo'
import { dashboard } from '@/guides/endpoints/dashboard'
import { list as projects } from '@/guides/endpoints/projects'

// Trigger must have calls/endpoints in cache.
export default () => {
  chrome()
  contact()
  news()
  me()
  profile()
  avatars()
  todo()
  dashboard()
  projects()
}
