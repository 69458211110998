
import { defineComponent, nextTick, ref, watch } from 'vue'
import { gsap } from 'gsap'
import Btn from '@/components/Btn.vue'
import Slidy from 'epic-slidy'

export default defineComponent({
  name: 'TipsModal',
  components: {
    Btn,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line

  inject: ['device'],

  setup(props, ctx) {
    let slider: Slidy
    const items = ref<null | HTMLElement>(null)

    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
      document.cookie = 'accept_tips=true'
    }

    const transition = (
      currentSlide: HTMLElement | HTMLElement[],
      newSlide: HTMLElement | HTMLElement[]
    ) =>
      new Promise<void>(resolve => {
        const tl = gsap.timeline({
          onComplete: () => {
            resolve()
          },
        })

        tl.add('transition')
          .set(newSlide, {
            zIndex: 3,
          })
          .to(
            currentSlide,
            {
              duration: 0.5,
              order: 1,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            newSlide,
            {
              duration: 0.5,
              order: 0,
              ease: 'power4',
            },
            'transition'
          )
      })

    const showSlider = () => {
      if (props.visible) {
        nextTick(() => {
          slider = new Slidy(items.value as HTMLElement, {
            click: false,
            controls: `<span>Découvrir la suite</span>`,
            drag: true,
            loop: false,
            namespace: 'tips-slidy',
            nav: '<div><span>${number}</span></div>',
            swipe: true,
            transition,
            interval: 3000,
          })

          slider.init()
        })
      }
    }

    watch(() => props.visible, showSlider)

    return {
      close,
      items,
    }
  },
})
