
import { defineComponent, ref } from 'vue'
import { send } from '@/guides/endpoints/pictures'

import FormInput from '@/components/FormInput.vue'
import Alert from '@/components/Alert.vue'
import Upload from '@/components/Upload.vue'
import Feedback from '@/components/Feedback.vue'
import Btn from '@/components/Btn.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Upload,
    Feedback,
    Btn,
  },
  setup() {
    const isOpen = ref(false)
    const showAlert = ref(false)
    const upload = ref()
    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')
    const feedbackVisible = ref(false)
    const loading = ref(false)

    const open = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    // const showFeedback = () => {
    //   feedbackVisible.value = true
    // }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.delete('photos[]')
      const files = upload.value.getFiles()

      if (files.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      send(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      isOpen,
      open,
      close,

      formel,
      showError,
      showAlert,
      upload,
      badgeFeedback,
      feedbackVisible,
      loading,

      onSubmit,
    }
  },
})
