<template>
  <transition name="fade">
    <div class="tips-modal" v-if="visible">
      <div class="layer" @click.prevent="close"></div>

      <div class="tips-modal__content">
        <ul class="tips__list" ref="items">
          <li class="tips__item">
            <div class="tips-modal__icon">
              <SvgSprite symbol="ui-saved" size="16 16" />
            </div>
            <div class="tips-modal__counter">Tips No 1</div>
            <h3 class="tips-modal__title">MyGuides, même hors connexion</h3>
            <div class="tips-modal__text">
              <p>
                À chaque fois que tu consultes une page de MyGuides en ligne,
                elle est ensuite automatiquement disponible hors connexion !
              </p>
            </div>
          </li>
          <li class="tips__item">
            <div class="tips-modal__icon">
              <SvgSprite symbol="ui-chrome" size="16 16" />
            </div>
            <div class="tips-modal__counter">Tips No 2</div>
            <h3 class="tips-modal__title">
              Télécharge MyGuides - {{ device.browser.name }}
            </h3>
            <div class="tips-modal__text">
              <p>
                Installe manuellement l'appli à partir de cette page de ton
                navigateur.
              </p>
              <p v-if="device.browser.name === 'Safari'">
                Clique sur l’icône
                <span class="icon"
                  ><SvgSprite symbol="ui-upload" size="16 16"/></span
                >. Descends jusque "Sur l'écran d'accueil" et ajoute MyGuides à
                ton smartphone/tablette.
              </p>
              <p v-else>
                Clique sur le menu de ton navigateur
                <span class="icon">
                  <SvgSprite symbol="ui-dots" size="16 16"/></span
                >, puis sur le bouton « installer ou ajouter à l'écran d'accueil
                »
              </p>
              <p>
                Et voilà, c’est fait ! Besoin d’aide ? Retrouve toutes les infos
                sur
                <a href="https://www.guides.be" target="_blank">www.guides.be</a
                >.
              </p>
            </div>
            <Btn class="tips-modal__btn" @click.prevent="close"
              >Merci, j'ai compris</Btn
            >
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue'
import { gsap } from 'gsap'
import Btn from '@/components/Btn.vue'
import Slidy from 'epic-slidy'

export default defineComponent({
  name: 'TipsModal',
  components: {
    Btn,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line

  inject: ['device'],

  setup(props, ctx) {
    let slider: Slidy
    const items = ref<null | HTMLElement>(null)

    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
      document.cookie = 'accept_tips=true'
    }

    const transition = (
      currentSlide: HTMLElement | HTMLElement[],
      newSlide: HTMLElement | HTMLElement[]
    ) =>
      new Promise<void>(resolve => {
        const tl = gsap.timeline({
          onComplete: () => {
            resolve()
          },
        })

        tl.add('transition')
          .set(newSlide, {
            zIndex: 3,
          })
          .to(
            currentSlide,
            {
              duration: 0.5,
              order: 1,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            newSlide,
            {
              duration: 0.5,
              order: 0,
              ease: 'power4',
            },
            'transition'
          )
      })

    const showSlider = () => {
      if (props.visible) {
        nextTick(() => {
          slider = new Slidy(items.value as HTMLElement, {
            click: false,
            controls: `<span>Découvrir la suite</span>`,
            drag: true,
            loop: false,
            namespace: 'tips-slidy',
            nav: '<div><span>${number}</span></div>',
            swipe: true,
            transition,
            interval: 3000,
          })

          slider.init()
        })
      }
    }

    watch(() => props.visible, showSlider)

    return {
      close,
      items,
    }
  },
})
</script>

<style lang="scss" scoped>
.tips-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;

  @include mq(m) {
    top: 8.6rem;
    height: calc(100% - 8.6rem);
    width: calc(100% - 40rem);
  }

  @include mq(xl) {
    width: calc(100% - 48rem);
  }
}

.layer {
  @include get-all-space;
  background: rgba($botticelli, 0.9);
  cursor: pointer;
}

.tips-modal__content {
  @include center-xy;

  padding: $spacing * 1.5;
  display: flex;
  flex-direction: column;
  width: calc(100% - 8rem);
  // height: 30rem;
  background: $c-white;
  text-align: center;

  ::v-deep(button) {
    max-width: 30rem;
  }

  @include mq(s) {
    // padding: $spacing * 1.5;
    width: 55%;
  }

  @include mq(xl) {
    // padding: $spacing * 3;
    width: 60%;
  }
}

.tips__list {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  width: 200%;
  pointer-events: none;
}

.tips__item {
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  // pointer-events: none;

  &:first-child {
    z-index: 2;
    order: 0;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }
}

::v-deep(.tips-slidy-nav) {
  list-style: none;
  margin-top: $spacing;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.tips-slidy-nav__item) {
  margin: 0 0.4rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: $botticelli;
  border-radius: 99em;

  span {
    display: none;
  }

  &.is-active {
    background-color: $tangerine;
  }
}

::v-deep(.tips-slidy-controls) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25%;
  padding: 0 $spacing * 1.5;
  z-index: 4;

  button {
    @include fira-bold;
    width: 100%;
    background: $regal-blue;
    color: $c-white;
    border: 0;
    text-align: center;
    padding: 1.45rem;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.8rem;

    @include mq(m) {
      font-size: 1.4rem;
    }
  }

  button:hover {
    opacity: 0.8;
  }

  button:disabled,
  button[disabled] {
    display: none;
  }

  .tips-slidy-controls__item--prev {
    display: none;
  }
}

.tips-modal__icon {
  position: relative;
  display: block;
  margin: -6rem auto 2rem;
  width: 6rem;
  height: 6rem;
  border-radius: 999em;
  background-color: $regal-blue;

  svg {
    @include center-xy;

    width: 3rem;
    height: 3rem;
  }
}

.tips-modal__counter {
  @include fira;

  margin-bottom: $spacing * 0.3;
  color: $rock-blue;
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.03em;
  text-align: center;
}

.tips-modal__title {
  margin-bottom: $spacing * 0.7;
}

.tips-modal__text {
  margin-bottom: $spacing * 1.5;

  p {
    margin-bottom: 1rem;
    font-size: 1.2rem;

    @include mq(s) {
      font-size: 1.6rem;
    }
  }

  .icon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
  }

  a {
    color: $regal-blue;
    text-decoration: underline;
  }
}

.tips-modal__btn {
  margin: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
