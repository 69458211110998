<template>
  <Alert :show="showAlert && !!showError?.message">
    <SvgSprite symbol="icons-alert" size="0 0 14 11" />
    <span>Erreur : {{ showError?.message }}</span>
  </Alert>

  <div class="share" v-if="isOpen">
    <div class="layer" @click.prevent="close"></div>

    <div class="box">
      <form
        ref="formel"
        @submit.prevent="onSubmit"
        method="POST"
        enctype="multipart/form-data"
      >
        <div class="mb-s">
          <h5 class="mb-xxs">
            Entre un titre avec une année*
          </h5>
          <FormInput name="folder" :required="true" />
          <h6>(Par exemple : Grand camp 2020 + Fête d'Unité avril 2020)</h6>
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">
            Entre un légende
          </h5>
          <textarea
            name="legend"
            placeholder="max 450 caractères espace compris"
            maxlength="450"
            required
          ></textarea>
        </div>

        <div class="documents m-s">
          <h5 class="mb-xxs">Ajoute tes photos</h5>
          <Upload
            ref="upload"
            name="photos[]"
            :max="100"
            :options="{}"
            :required="true"
          />
        </div>

        <div class="mb-s">
          <h5 class="mb-xxs">
            <router-link to="/mentions-legales">
              <i>Droit à l'image</i>
            </router-link>
          </h5>
          <label class="input-checkbox mb-xxs">
            <SvgSprite symbol="icons-ok" size="20 15" />
            <input required name="droit" value="accept" type="checkbox" />
            <span
              >J'ai vérifié que tous les parents ont donné leur accord pour
              l'utilisation de ces photos</span
            >
          </label>
        </div>

        <Btn class="send">Ok</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Tes photos sont en cours d'envoi...</h4>
        <p>Patiente encore un petit peu...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Bravo!</h4>
        <p>Merci d'avoir partagé tes photos. Nous les avons bien reçues !</p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { send } from '@/guides/endpoints/pictures'

import FormInput from '@/components/FormInput.vue'
import Alert from '@/components/Alert.vue'
import Upload from '@/components/Upload.vue'
import Feedback from '@/components/Feedback.vue'
import Btn from '@/components/Btn.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Upload,
    Feedback,
    Btn,
  },
  setup() {
    const isOpen = ref(false)
    const showAlert = ref(false)
    const upload = ref()
    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')
    const feedbackVisible = ref(false)
    const loading = ref(false)

    const open = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    // const showFeedback = () => {
    //   feedbackVisible.value = true
    // }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.delete('photos[]')
      const files = upload.value.getFiles()

      if (files.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      send(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      isOpen,
      open,
      close,

      formel,
      showError,
      showAlert,
      upload,
      badgeFeedback,
      feedbackVisible,
      loading,

      onSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
h6 {
  margin-top: 0.5rem;
  font-weight: 400;
  color: $hippie-blue;
}

.share {
  @include get-all-space;
  position: absolute;
  z-index: 15;

  @include mq(nav) {
    z-index: 9;
  }
}

.layer {
  @include get-all-space;
  cursor: pointer;
  background: rgba($botticelli, 0.9);
}

.box {
  @include center-xy;
  position: fixed;
  background: $c-white;
  width: 90%;
  max-width: 320px;
  padding: 30px 20px;
  max-height: calc(90vh - 90px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include mq(m) {
    left: calc(50% - 200px);
  }

  @include mq(xl) {
    left: calc(50% - 240px);
  }
}
</style>
