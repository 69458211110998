<template>
  <div class="slider wrapper">
    <div class="slider-inner">
      <ul class="slider__list" ref="items">
        <li class="slider__item" v-for="item in data" :key="item.id">
          <Task class="slider__item__task" :data="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { gsap } from 'gsap'
import Slidy from 'epic-slidy'
import Task from '@/components/Task.vue'

export default defineComponent({
  name: 'HomeSlider',
  components: {
    Task,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup() {
    let slider: Slidy
    const items = ref<null | HTMLElement>(null)

    const transition = (
      currentSlide: HTMLElement | HTMLElement[],
      newSlide: HTMLElement | HTMLElement[]
    ) =>
      new Promise<void>(resolve => {
        const tl = gsap.timeline({
          onComplete: () => {
            resolve()
          },
        })

        tl.add('transition')
          .set(newSlide, {
            opacity: 0,
            zIndex: 3,
          })
          .to(
            currentSlide,
            {
              duration: 0.5,
              opacity: 0,
              ease: 'power4.inOut',
            },
            'transition'
          )
          .to(
            newSlide,
            {
              duration: 0.5,
              opacity: 1,
              ease: 'power4',
            },
            'transition'
          )
      })

    onMounted(() => {
      slider = new Slidy(items.value as HTMLElement, {
        auto: true,
        click: false,
        controls:
          '<svg class="home-slidy-controls__item__icon" width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.80317 7.46967C9.09606 7.17678 9.57093 7.17678 9.86383 7.46967L13.8638 11.4697C14.0045 11.6103 14.0835 11.8011 14.0835 12C14.0835 12.1989 14.0045 12.3897 13.8638 12.5303L9.86383 16.5303C9.57093 16.8232 9.09606 16.8232 8.80317 16.5303C8.51027 16.2374 8.51027 15.7626 8.80317 15.4697L12.2728 12L8.80317 8.53033C8.51027 8.23744 8.51027 7.76256 8.80317 7.46967Z" fill="#F28C00"/></svg>', // eslint-disable-line max-len
        drag: true,
        namespace: 'home-slidy',
        nav: '<div><span>${number}</span></div>',
        swipe: true,
        transition,
        interval: 3000,
      })

      slider.init()
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      items,
    }
  },
})
</script>

<style lang="scss">
.slider {
  position: relative;
  margin: -6rem auto $spacing * 2;
  max-width: 55rem;

  @include mq(xl) {
    margin: -8rem auto $spacing * 2;
  }

  @include mq(xxl) {
    max-width: 65rem;
  }
}

.slider__list {
  list-style: none;
  position: relative;
  min-height: 14rem;

  @include mq(xl) {
    min-height: 16rem;
  }
}

.slider__item {
  @include get-all-space;

  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  .task.is-past {
    opacity: 1;

    > div {
      opacity: 0.3;
    }
  }
}

.slider__item__task {
  @include get-all-space;
}

.home-slidy-controls {
  display: none;

  @include mq(m) {
    margin: 0 auto -3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
  }

  [class*='home-slidy-controls__item--'] {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
  }

  [class*='home-slidy-controls__item--'][class*='--prev'] {
    transform: rotate(180deg);
  }
}

.home-slidy-nav {
  list-style: none;
  margin-top: $spacing;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.home-slidy-nav__item {
  margin: 0 0.4rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: $c-white;
  border-radius: 99em;

  span {
    display: none;
  }

  &.is-active {
    background-color: $tangerine;
  }
}
</style>
