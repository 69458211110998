
import { defineComponent, onMounted, ref } from 'vue'
import moment from 'moment'
import { me, profile } from '@/guides/endpoints/profile'
import { dashboard } from '@/guides/endpoints/dashboard'
import triggerMustCache from '@/guides/triggerMustCache'

import Btn from '@/components/Btn.vue'
import Card from '@/components/Card.vue'
import HomeSlider from '@/components/home/Slider.vue'
import SharePhoto from '@/components/SharePhoto.vue'
import TipsModal from '@/components/TipsModal.vue'
import WikiCard from '@/components/inventory/Card.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Btn,
    Card,
    HomeSlider,
    SharePhoto,
    TipsModal,
    WikiCard,
  },
  setup() {
    const dashboardData = ref()
    const info = ref()
    const month = moment().format('MMMM YYYY')
    const modalVisible = ref(false)
    const role = ref()

    me().then(r => {
      info.value = r.data
    })

    profile().then(r => {
      role.value = r.data?.role
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    })

    dashboard().then(r => {
      dashboardData.value = r?.data?.content
    })

    onMounted(() => {
      triggerMustCache()

      // setTimeout(() => (modalVisible.value = true), 3000)

      const cookie = document.cookie.match(
        /^(.*;)?\s*accept_tips\s*=\s*[^;]+(.*)?$/
      )
      if (!cookie) {
        setTimeout(() => (modalVisible.value = true), 3000)
      }
    })

    const sharePhoto = ref()
    const openShare = () => {
      sharePhoto.value.open()
    }

    return {
      dashboardData,
      info,
      modalVisible,
      month,
      sharePhoto,
      openShare,
      role,
    }
  },
})
