<template>
  <div class="home" v-if="dashboardData && role">
    <div class="hero">
      <div class="hero__content wrapper">
        <p class="hero__content__pdm h3">Photo du mois</p>
        <!-- <p class="hero__content__month">{{ month }}</p> -->
        <h1 class="hero__content__title" v-html="dashboardData.title"></h1>
      </div>

      <div class="hero__picture-outer">
        <img
          v-if="dashboardData.picture"
          class="hero__picture"
          v-src="dashboardData.picture"
        />
      </div>
    </div>

    <div class="home__spotify" v-if="role === 'anime' && dashboardData.spotify">
      <div
        class="home__list is-spotify"
        v-html="dashboardData.spotify.iframe"
      ></div>
    </div>

    <HomeSlider
      class="home__slider"
      v-else-if="dashboardData.todos.length > 0"
      :data="dashboardData.todos"
    ></HomeSlider>

    <div class="home__section wrapper">
      <div class="home__list is-news">
        <div class="home__list__header">
          <h3>Actualités</h3>
          <router-link :to="{ name: 'News' }">
            <span>Voir tout</span>
            <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16" />
          </router-link>
        </div>
        <div class="home__list__items">
          <Card
            :data="news"
            v-for="news in dashboardData.news"
            :key="`news-${news.title}`"
          />
        </div>
      </div>

      <div class="home__list is-wiki">
        <div class="home__list__header">
          <h3>Espace de partage</h3>
          <router-link :to="{ name: 'Wiki' }">
            <span>Voir tout</span>
            <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16" />
          </router-link>
        </div>
        <div class="home__list__items">
          <div class="cards">
            <router-link
              v-for="item in dashboardData.wiki"
              :to="{ path: item.url }"
              :key="item.url"
              :href="item.url"
            >
              <WikiCard :data="item" />
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="home__list is-spotify"
        v-if="role != 'anime' && dashboardData.spotify"
        v-html="dashboardData.spotify.iframe"
      ></div>

      <div class="home__list is-highlight">
        <div class="home__list__header">
          <h3>Highlight</h3>
        </div>
        <div class="home__list__items">
          <div class="home__cta">
            <h4>
              <SvgSprite symbol="ui-photo" size="60" />
              <span>{{ dashboardData.upload.title }}</span>
            </h4>
            <p v-html="dashboardData.upload.text"></p>
            <Btn @click="openShare">
              <span>{{ dashboardData.upload.label }}</span>
              <SvgSprite symbol="ui-plus" size="16" />
            </Btn>
          </div>
        </div>
      </div>
    </div>

    <SharePhoto ref="sharePhoto" />

    <TipsModal v-model:visible="modalVisible" />

    <div class="footer">
      <router-link :to="{ path: '/mentions-legales' }">
        Mentions légales
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import moment from 'moment'
import { me, profile } from '@/guides/endpoints/profile'
import { dashboard } from '@/guides/endpoints/dashboard'
import triggerMustCache from '@/guides/triggerMustCache'

import Btn from '@/components/Btn.vue'
import Card from '@/components/Card.vue'
import HomeSlider from '@/components/home/Slider.vue'
import SharePhoto from '@/components/SharePhoto.vue'
import TipsModal from '@/components/TipsModal.vue'
import WikiCard from '@/components/inventory/Card.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Btn,
    Card,
    HomeSlider,
    SharePhoto,
    TipsModal,
    WikiCard,
  },
  setup() {
    const dashboardData = ref()
    const info = ref()
    const month = moment().format('MMMM YYYY')
    const modalVisible = ref(false)
    const role = ref()

    me().then(r => {
      info.value = r.data
    })

    profile().then(r => {
      role.value = r.data?.role
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    })

    dashboard().then(r => {
      dashboardData.value = r?.data?.content
    })

    onMounted(() => {
      triggerMustCache()

      // setTimeout(() => (modalVisible.value = true), 3000)

      const cookie = document.cookie.match(
        /^(.*;)?\s*accept_tips\s*=\s*[^;]+(.*)?$/
      )
      if (!cookie) {
        setTimeout(() => (modalVisible.value = true), 3000)
      }
    })

    const sharePhoto = ref()
    const openShare = () => {
      sharePhoto.value.open()
    }

    return {
      dashboardData,
      info,
      modalVisible,
      month,
      sharePhoto,
      openShare,
      role,
    }
  },
})
</script>

<style lang="scss" scoped>
.home {
  .icon-arrow {
    stroke: $tangerine;
    stroke-width: 2;
  }
}
.hero {
  display: block;
  margin-top: -3.5rem; // Header
  position: relative;
  // height: 42.5rem;
  overflow: hidden;
  background: transparent;
}

.hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 55rem;

  @include mq(xxl) {
    max-width: 65rem;
  }
}

.hero__content__month {
  @include fira-bold;

  margin-bottom: $spacing;
  color: $c-white;
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: capitalize;

  @include mq(m) {
    font-size: 2.4rem;
  }
}

.hero__content__pdm {
  margin-top: 7rem;
  margin-bottom: 1.6rem;
  color: $rock-blue;
}

.hero__content__title {
  margin-bottom: 1rem;
  line-height: 1;
  text-align: center;
}

.hero__picture-outer {
  @include aspect-ratio(375, 237);

  position: relative;

  @include mq(s) {
    @include aspect-ratio(96, 49);
  }
}

.hero__picture {
  @include get-all-space;
  object-fit: cover;
}

.home__section {
  margin-top: $spacing * 2.5;
  margin-bottom: $spacing * 2;
  max-width: 55rem;

  @include mq(xxl) {
    max-width: 120rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
}

.home__slider + .home__section {
  margin-top: 0;
}

.home__list {
  &.is-news {
    margin-bottom: $spacing * 1.5;

    @include mq(m) {
      display: none;
    }
  }

  &.is-wiki {
    @include mq(xxl) {
      grid-area: 1 / 1 / 2 / 3;
      display: flex;
      flex-direction: column;

      .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        height: 100%;
      }
    }
  }

  &.is-highlight {
    .home__list__header {
      display: none;
      @include mq(xxl) {
        display: block;
      }
    }
    @include mq(xl) {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  &.is-spotify {
    margin-bottom: 1rem;
  }
}

.home__list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.home__list__header a {
  display: flex;
  align-items: center;
  color: $tangerine;
  font-size: 1.4rem;
  line-height: 1.4;

  &:hover {
    opacity: 0.8;

    svg {
      transform: translateX(3px);
    }
  }

  svg {
    transition: transform 0.3s;
  }

  span {
    margin-right: $spacing / 2;
  }
}

.home__list__items {
  flex: 1;

  .card {
    margin-bottom: 1.5rem;
  }
}

.cards {
  a {
    display: block;

    @include mq($until: xxl) {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .card {
    height: 100%;
  }
}

$y: (
  xxs: 15px,
  l: 30px,
);
$x: (
  xxs: 20px,
  l: 30px,
);

.home__cta {
  background-color: $hippie-blue;
  color: $c-white;
  position: relative;
  @include fluid(padding-top, $x);
  @include fluid(padding-right, $y);
  @include fluid(padding-bottom, $x);
  @include fluid(padding-left, $y);

  h4 {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing / 2;
    color: $c-white;

    svg {
      margin-top: -6px;
      margin-left: -6px;
      margin-bottom: $spacing / 2;
    }
  }

  p {
    margin-bottom: $spacing;
    font-size: 1.4rem;

    @include mq(m) {
      font-size: 1.6rem;
    }
  }

  ::v-deep(button) {
    display: flex;
    align-items: center;
    width: auto;
    text-align: left;
    text-transform: none;

    font-size: 1.4rem;

    svg {
      margin-left: $spacing;
    }

    @include mq(m) {
      font-size: 1.8rem;
    }
  }

  .branch-horizon & {
    background-color: $branch-horizon;
  }

  .branch-route & {
    background-color: $branch-route;
  }
}

::v-deep(.task__picto-outer) {
  @include mq(l) {
    width: 12rem;
    margin-left: 2rem;
  }
}

::v-deep(.task__picto) {
  @include mq(l) {
    width: 100%;
  }
}

::v-deep(.task__content) {
  .task__content__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .task__content__link {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 190px;
    height: 1.2em;
    white-space: nowrap;
  }

  @include mq(l) {
    flex: 1;
    margin-right: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .task__content__title {
      font-size: 1.8rem;
    }
  }
}

::v-deep(.task__done) {
  @include mq(l) {
    width: 15rem;
    height: 100%;

    &::before {
      width: 5rem;
      height: 5rem;
    }

    .task__done__check {
      width: 5rem;
      height: 5rem;

      svg {
        width: 28px;
        height: 19px;
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid $rock-blue;
  padding: 10px 0;
  margin-top: 80px;
  padding-bottom: 15px !important;
}

.footer a {
  @include fira;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;
  color: $rock-blue;

  @include mq(l) {
    font-size: 14px;
  }
}

.home__spotify {
  position: relative;
  margin: -4rem auto $spacing * 2;
  max-width: 55rem;

  @include mq(xxl) {
    max-width: 65rem;
  }
}
</style>
